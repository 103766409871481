import React, {useRef} from "react";
import "./Join.css";
import emailjs from "@emailjs/browser";


const Join = () => {
    const form = useRef();
    const sendEmail = (e) => {
      e.preventDefault();

      emailjs.sendForm('service_ejgde22', 'template_aswm0nf', form.current, 'LPCYWmkeTeb2OJED6')
        .then((result) => {
          console.log(result.text);
         }, (error) => {
        console.log(error.text);
         });
        };
            
  return (
    <div className="join" id="join-us">
      <div className="left-j">
      <hr />
        <div>
          <span className="stroke-text">READY TO</span>
          <span>LEVEL UP</span>
        </div>
        <div>
          <span>YOUR BODY</span>
          <span className="stroke-text">WITH US?</span>
        </div>
      </div>
      <div className="right-j">
        <form ref={form} className="email-container" onSubmit= {sendEmail}>
           <input type="text" name="user_name" id="" 
           placeholder="Enter Your Full Name" />
            <input type="email" name="user_email" id=""
            placeholder="Enter Your Email Address" />
            <button className="btn btn-j">Join Now</button>
        </form>
      </div>
    </div>
  );
};

export default Join;
